import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Carpòfors globulosos o en forma de pera, d’1 a 5 cm de diàmetre i uns 6 cm d’altura, de color blanc gris o blanc brut quan és adult. A la superfície externa té una ornamentació formada per petites perles color crema, rodones o còniques que cauen a la maduresa deixant un senyal. La gleva és de color blanc que després es transforma en una pols de color marró que s’escapa per l’ostíol situat a la part superior. Les espores són marrons en massa, rodones, llises, de 3-4 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      